<template>
    <div class="home-box">
        <el-page-header @back="goBack" :content="pageTitle">
        </el-page-header>
        <!-- 查询区域 -->
        
        <!-- table区域-begin -->
        <div class="form-box" v-loading="loading">
            <div>
                <div class="el-dialog-header-box">
                    <div class="dialog-title-block"></div>
                    <span class="dialog-title-text">商家信息</span>
                </div>
                <el-divider />
            </div>
            <el-descriptions column="2">
                <el-descriptions-item label="商家名称">{{orderInfo.storename}}</el-descriptions-item>
            </el-descriptions>

            <div>
                <div class="el-dialog-header-box">
                    <div class="dialog-title-block"></div>
                    <span class="dialog-title-text">商品信息</span>
                </div>
                <el-divider />
            </div>
            <el-descriptions column="2">
                <el-descriptions-item label="商品名称">{{''}}</el-descriptions-item>
            </el-descriptions>

            <div>
                <div class="el-dialog-header-box">
                    <div class="dialog-title-block"></div>
                    <span class="dialog-title-text">订单信息</span>
                </div>
                <el-divider />
            </div>
            <el-descriptions column="2">
                <el-descriptions-item label="订单号">{{itemsData.order_id}}</el-descriptions-item>
                <el-descriptions-item label="支付金额">{{itemsData.receipt_amount}}</el-descriptions-item>
                <el-descriptions-item label="原始金额">{{itemsData.original_amount}}</el-descriptions-item>
                <el-descriptions-item label="支付优惠">{{itemsData.discount_amount}}</el-descriptions-item>
                <el-descriptions-item label="状态">{{itemStatus[itemsData.order_status]}}</el-descriptions-item>
                <el-descriptions-item label="订单类型">{{orderType[itemsData.order_type]}}</el-descriptions-item>
                <el-descriptions-item label="时间">{{itemsData.update_order_time}}</el-descriptions-item>
            </el-descriptions>

            <div>
                <div class="el-dialog-header-box">
                    <div class="dialog-title-block"></div>
                    <span class="dialog-title-text">券码</span>
                </div>
                <el-divider />
            </div>
            <div class="table-box">
                <el-table :data="itemsData.certificate" stripe style="width: 100%">
                    <el-table-column prop="certificate_id" label="券码号" width="180">
                    </el-table-column>
                    <el-table-column prop="item_status" label="状态" min-width="80">
                      <template slot-scope="scope">
                        <span>{{ itemStatus[scope.row.item_status] }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="item_update_time" label="券更新时间" min-width="120">
                    </el-table-column>
                    <el-table-column prop="order_item_id" label="券维度的订单" min-width="220">
                    </el-table-column>
                    <el-table-column prop="refund_amount" label="退款金额" min-width="120">
                    </el-table-column>
                    <el-table-column prop="refund_time" label="退款时间" min-width="120">
                    </el-table-column>
                </el-table>
            </div>
    
            <div>
                <div class="el-dialog-header-box">
                    <div class="dialog-title-block"></div>
                    <span class="dialog-title-text">商品</span>
                </div>
                <el-divider />
            </div>
            <div class="table-box">
                <el-table :data="itemsData.products" stripe style="width: 100%">
                    <el-table-column prop="product_name" label="商品名称" min-width="120">
                    </el-table-column>
                    <el-table-column prop="product_id" label="商品Id" min-width="120">
                    </el-table-column>
                    <el-table-column prop="num" label="商品数量" min-width="40">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- table区域-end -->

    </div>
  </template>
    
    <script>
  import Vue from "vue";
  import { mapState, mapMutations } from "vuex";
  import axios from "axios";
  
  export default Vue.extend({
    name: "ordersDetails",
    data() {
      return {
        form: {
          name: "",
          region: "",
          imageUrl: "",
          delivery: "2021004128673263",
          type: "",
          resource: "/storage/topic_local/b0/32028407d83900d32ac60ebba67443.crt",
          desc: "/storage/topic_local/23/ec45571786ea8dfbd201547060f143.crt",
          rootCertificate:
            "/storage/topic_local/b6/612a80b13013892c8c5c0829f62367.crt",
        },
        tableData: [{
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        }, {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        }, {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        }, {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }],

        agentBalance: {},
        dailiBalance: {},
        orderInfo: {},
        itemStatus: {},
        itemsData: {},
        orderType: {},

        dialogVisible: false,
        disabled: false,
        pageTitle: '',
        pageTitleList: [
          {id: '1',name: '已支付订单详情'},
          {id: '2',name: '已核销记录详情'},
          {id: '3',name: '已取消记录详情'},
          {id: '4',name: '已退款记录详情'}
        ],
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    created() {
      this.pageTitle = this.pageTitleList.find(item => item.id==this.$route.query.type).name
      if (this.$route.query.id) {
        this.getOrderDetails(this.$route.query.id)
      } else {
        this.$message({
          message: '未获取订单ID',
        });
        this.goBack()
      }
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      // this.getautoshow()
      // this.getInfo()
      // this.getechart();
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      getechart() {
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById("chart"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: "核销券数",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            // prettier-ignore
            data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
            axisPointer: {
              snap: true,
            },
          },
          series: [
            {
              smooth: true,
              data: [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                12,
                33,
                222,
                333,
                123,
                233,
                112,
                212,
                444,
                333,
                1,
                11,
                22,
              ],
              type: "line",
            },
          ],
        });
      },
      getInfo() {
        axios
          .post("http://shunyu.jnwww.top/admin.php?action=setting&method=store", {
            is_get: 1,
            token: this.token,
          })
          .then((res) => {
            console.log(res);
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            let data = {
              store: res.data.data.store_name,
              logo: res.data.data.store_logo,
            };
            this.setsetting(data);
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getautoshow() {
        axios
          .get(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
            {
              params: {
                token: this.token,
              },
            }
          )
          .then((res) => {
            if (res.data.msg == "登录已过期") {
              localStorage.removeItem("user");
              this.$router.replace("/");
              return;
            }
            res.data.data.data.forEach((item) => {
              this.options.push({
                label: item.exhibition_title,
                value: item.exhibition_id,
              });
              this.value = res.data.data.data[0].exhibition_id;
              this.getList();
            });
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      getList() {
        axios
          .post(
            "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
            {
              exhibition_ids: this.value,
              begin_time: this.date[0],
              end_time: this.date[1],
              page_idx: this.page,
              page_size: 10,
              token: this.token,
            }
          )
          .then((res) => {
            this.tableData = res.data.data.data;
            if (res.data.data.data.length == 0) {
              this.empty = this.page;
              this.$message.error("已无数据");
            } else {
              this.empty = 10000000000000000;
            }
          })
          .catch((err) => {
            this.$message.error(err.msg);
          });
      },
      handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        console.log("file.type", file.type);
        const isJPG = file.type === "image/jpeg";
        const isPNG = file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG && !isPNG) {
          this.$message.error("上传图片只能是 jpg、png 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        return isJPG && isLt2M;
      },
      handleRemove(file) {
        console.log(file);
        this.form.imageUrl = "";
      },
      handlePictureCardPreview(file) {
        this.form.imageUrl = file.url;
        this.dialogVisible = true;
      },
      handleDownload(file) {
        console.log(file);
      },
      onSubmit() {},
      goBack() {
        console.log('go back');
        this.$router.back()
      },
      // 获取订单详情
      getOrderDetails(id) {
        this.loading = true
        this.$api.getOrderDetails({id}).then(
          res => {
            if(res.code == 'success'){
              this.agentBalance = res.data.agent_balance
              this.dailiBalance = res.data.daili_balance
              this.orderInfo = res.data.info
              this.itemStatus = res.data.item_status
              this.itemsData = res.data.items
              this.orderType = res.data.order_type

              // this.typeOptions = Object.values(res.data.type)
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
    
    <style scoped lang="less">
  @import "../../assets/less/easyElement.less";
  </style>
  
  <style>
  .avatar-uploader .el-upload {
    border: 1px dashed #949494;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
  .el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 0 0 24px;
  }
  </style>
    